import TableRow from "@mui/material/TableRow";
import {StyledTableCellBody} from "../shared/tableStyle";
import Moment from "react-moment";
import * as React from "react";
import {DateFormat} from "./rebateListTable";
import {useContext, useEffect} from "react";
import {GlobalStore} from "../../stores/globalContext";
import {observer} from "mobx-react-lite";

const CollapsedRow = observer((props) => {
    const stores = useContext(GlobalStore);

    /*
    useEffect(() => {
        //Runs only on the first render
        let rebateAmount;
        rebateAmount = props.row['rebateAmount'] * ((props.row['stateQuantity'] === null)
            ?(props.row['venueQuantity'] === null )
                ? Number(props.row['scanQuantity'])
                : props.row['venueQuantity']
            :props.row['stateQuantity']);
        setRebateAmount(rebateAmount);
        props.handleRebateAmount(rebateAmount);
    }, []);
     */

    useEffect(() => {
        stores.member.updateRebateNostraAmount(props.row);
        props.handleTotalAmount();
        props.handleRebateAmount(props.row.rangeCode);
    }, [props.row['venueQuantity'],props.row['stateQuantity']]);

  return(
        <TableRow key={props.row.rebateId} sx={{ visibility: props.open ? 'visible' : 'collapse' }}>
            <StyledTableCellBody component="th" scope="row"></StyledTableCellBody>
            <StyledTableCellBody align="left">{props.row.productName}</StyledTableCellBody>
            <StyledTableCellBody align="center">{props.row.volume}</StyledTableCellBody>
            <StyledTableCellBody align="center">{props.row.multibuyQuantity}</StyledTableCellBody>
            <StyledTableCellBody align="center">
                <Moment date={props.row.productPromoStartDate} format={DateFormat}/>
                <br />
                <Moment date={props.row.productPromoEndDate} format={DateFormat}/>
            </StyledTableCellBody>
            <StyledTableCellBody align="center">${props.row.rebateAmount}</StyledTableCellBody>
            <StyledTableCellBody align="center">{props.row.scanQuantity}</StyledTableCellBody>
            {stores.member.getScrbMode() === 'state' ?
                <>
                    <StyledTableCellBody align="center">{props.row['venueQuantity']}</StyledTableCellBody>
                    {
                        // change the state quantity if it's under editing
                        (props.edit === true && (
                            props.data['stateFormStatus'] === 'Available' ||
                            props.data['stateFormStatus'] === 'Reviewed'))
                            ? <StyledTableCellBody align="center">
                                <input type="number"
                                       min="0"
                                       style={{width:50}}
                                       data-rebateid={props.row['rebateId']}
                                       onChange={(e)=>
                                           stores.member.updateVenueQuantityByRebateId(props.row['rebateId'],
                                               e.target.value,'stateQuantity')}
                                       value={props.row['stateQuantity'] ? props.row['stateQuantity'] : ""} />
                            </StyledTableCellBody>
                            : <StyledTableCellBody align="center">
                                {Number(props.row['stateQuantity'])}
                            </StyledTableCellBody>
                    }
                </>
                :
                <>
                    {
                        // change the state quantity if it's under editing}
                        (props.edit === true && (
                            props.data['venueFormStatus'] === 'Available' ||
                            props.data['venueFormStatus'] === 'Submitted'))
                            ? <StyledTableCellBody align="center">
                                <input type="number"
                                       min="0"
                                       style={{width:50}}
                                       data-rebateid={props.row['rebateId']}
                                       onChange={(e)=>
                                           stores.member.updateVenueQuantityByRebateId(props.row['rebateId'],
                                               e.target.value,'venueQuantity')}
                                       value={props.row['venueQuantity'] ? props.row['venueQuantity'] : ""} />
                            </StyledTableCellBody>
                            : <StyledTableCellBody align="center">
                                {Number(props.row['venueQuantity'])}
                            </StyledTableCellBody>
                    }
                </>
            }
            <StyledTableCellBody align="center">
                ${(props.row.payable ? props.row.payable : 0).toFixed(2)}
            </StyledTableCellBody>
        </TableRow>
    )
});

export default CollapsedRow;
