import './App.css';
import {BrowserRouter, matchPath, Navigate, Route, Routes, useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {GlobalStore} from './stores/globalContext';
import stores from "./stores";
import MainScreen from "./components/main/main";
import {createTheme, ThemeProvider} from "@mui/material";
import {orange} from "@mui/material/colors";
import AllForms from "./components/main/allForms";
import VenueList from "./components/venues/venueList";
import OnlineRebateForm from "./components/rebate-form/onlineRebateForm";
import LoginScreen from "./components/login/login";
import {mainTheme} from "./components/shared/theme";

const ThemeContext = React.createContext(mainTheme());
export const useMyTheme = ()=>React.useContext(ThemeContext);

function App() {
  const [theme,setMyTheme] = useState(mainTheme());
  return (
    <ThemeContext.Provider value={{theme,setMyTheme}}>
      <ThemeProvider theme={theme}>
        <GlobalStore.Provider value={stores}>
          <BrowserRouter>
            <Routes>
              <Route index path="/:banner/login" element={<LoginScreen />}/>
              <Route index element={<Navigate to="/bottlemart/login" />} />
              <Route path="/" element={<MainScreen />} >
                <Route path=":banner/all-forms/:promo?/:year?" element={<AllForms />} />
                <Route path=":banner/venue-list/:promoId" element={<VenueList />} />
                <Route path=":banner/online-rebate-form/:promoId/:storeId" element={<OnlineRebateForm />} />
              </Route>
            </Routes>
          </BrowserRouter>
        </GlobalStore.Provider>
      </ThemeProvider>
    </ThemeContext.Provider>
  );
}

export default App;
