import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import {useNavigate, useParams} from "react-router-dom";
import {useContext} from "react";
import {GlobalStore} from "../../stores/globalContext";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function CancelDialogSlide() {
    const [open, setOpen] = React.useState(false);
    const navigate = useNavigate();
    const stores = useContext(GlobalStore);
    const { banner } = useParams();
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleSubmit = () => {
        navigate(`/${banner}/venue-list/${stores.member.scanRebateData.promoCode}`);
    }

    return (
        <div>
            <Button variant="outlined"
                    size="large"
                    style={{paddingLeft: 50, paddingRight: 50}}
                    onClick={handleClickOpen}>
                Cancel &amp; close
            </Button>
            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle>{"Leaving Rebate From"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        Are you sure you want to leave without saving your changes?
                    </DialogContentText>
                </DialogContent>
                <DialogActions style={{justifyContent: 'space-between'}}>

                    <Button onClick={handleClose}>RETURN TO FORM</Button>
                    <Button variant={'contained'} onClick={handleSubmit}>YES, CANCEL CHANGES</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
