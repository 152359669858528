import * as React from "react";
import RowManual from './rowManual';
import RowNostra from "./rowNostra";
import {observer} from "mobx-react-lite";

const MyTableBody = observer((props) => {
    if(props.data.memberType === 'Manual') {
        return (
            props.data.extendedRebateList.map((row, index) => {
                return <RowManual key={index} row={row} index={index} {...props} />;
            })
        );
    } else if(props.data.memberType ==='NostraData') {
        return (
            props.data.extendedRebateList.map((row, index) => {
                return <RowNostra key={index} row={row} index={index} {...props} />;
            })
        );
    }
});

export default MyTableBody;
