import {makeObservable, observable, runInAction} from 'mobx';
import {getFromApi,postToApi} from './fetchStore';

export default class MemberStore {
    constructor() {
        makeObservable(this,{
            rebateData: observable,
            banner: observable,
            scrb_mode: observable,
            emailAddress: observable,
        })
        this._retrieveData();
    }
    banner = 'bottlemart';
    scrb_mode = '';
    rebateData = [];
    emailAddress = null;

    setBanner = (banner) => {
        switch(banner) {
            case 'thirstycamel':
                // code block
                runInAction(()=>{
                    this.banner = 'thirstycamel';
                })
                break;
            case 'bottlemart':
                // code block=
                runInAction(()=>{
                    this.banner = 'bottlemart';
                })
                break;
            case 'sipnsave':
                // code block
                runInAction(()=>{
                    this.banner = 'sipnsave';
                })
                break;
            case 'harrybrown':
                // code block
                runInAction(()=>{
                    this.banner = 'bottlemart';
                })
                break;
            default:
                // code block
                runInAction(()=>{
                    this.banner = 'bottlemart';
                })
        }
    }

    _retrieveData = () => {
        try {
            const data = sessionStorage.getItem('@USER_DATA');
            if (data !== null) {
                const user = JSON.parse(data)
                runInAction(()=> {
                    this.emailAddress = user.emailAddress;
                    this.scrb_mode = user.scrb_mode;
                });
            }
        } catch (error) {
            // Error retrieving data
            console.error("Error retrieving data: sessionStorage")
        }
    }

    get scanRebateData() {
        return this.rebateData
    }

    handleFormSubmit = async () => {
        let saveForm = {};
        if(this.getScrbMode() === 'state'){
            saveForm = {
                "promoCode": this.rebateData.promoCode,
                "VenueCode": this.rebateData.venueCode,
                "Comment": this.scanRebateData.comment,
                "Rebates": this.getFormData(),
                "Attachments": []
            }
        } else {
            saveForm = {
                "promoCode": this.rebateData.promoCode,
                "VenueCode": this.rebateData.venueCode,
                "completedBy": this.rebateData.completedBy,
                "Rebates": this.getFormData(),
                "Attachments": []
            }
        }
        //console.log(saveForm);
        await this.postRebates(saveForm);
    }

    // Function to update the venueQuantity based on rebateId
    updateVenueQuantityByRebateId = (rebateId, qty,scrbMode) => {
        // Iterate over the extendedRebateList array
        runInAction(() => {
            this.rebateData.extendedRebateList.forEach((rebate) => {
                // Find the rebate object with matching rebateId
                const targetRebate = rebate.rebateList.find((item) => item.rebateId === rebateId);
                if (targetRebate) {
                    // Update the venueQuantity property
                    if(this.getScrbMode() === 'state') {
                        runInAction(() => {
                            targetRebate.stateQuantity = qty;
                        });
                    } else {
                        runInAction(() => {
                            targetRebate.venueQuantity = qty;
                        });
                    }
                }
            });
        });
    };

    updateRebateNostraAmount = (currentProduct) => {
        runInAction(() => {
            this.rebateData.extendedRebateList.forEach((rebate) => {
                // Find the rebate object with matching rebateId
                const targetRebate = rebate.rebateList.find((item) => item.rebateId === currentProduct['rebateId']);
                if (targetRebate) {
                    let rebateAmount;
                        rebateAmount = currentProduct['rebateAmount'] * ((currentProduct['stateQuantity'] === null)
                            ?(currentProduct['venueQuantity'] === null )
                                ? Number(currentProduct['scanQuantity'])
                                : currentProduct['venueQuantity']
                            :currentProduct['stateQuantity']);
                    runInAction(() => {
                        targetRebate.payable = rebateAmount;
                    });
                }
            });
        });
    }

    updateRebateAmount = (currentProduct) => {
        runInAction(() => {
            this.rebateData.extendedRebateList.forEach((rebate) => {
                // Find the rebate object with matching rebateId
                const targetRebate = rebate.rebateList.find((item) => item.rebateId === currentProduct['rebateId']);
                if (targetRebate) {
                    let rebateAmount;
                    if(this.getScrbMode() === 'state') {
                        rebateAmount = currentProduct['rebateAmount'] * (
                            (currentProduct['stateQuantity'] === null)
                                ? Number(currentProduct['venueQuantity'])
                                : Number(currentProduct['stateQuantity']));
                    } else {
                        rebateAmount = currentProduct['rebateAmount'] * Number(currentProduct['venueQuantity']);
                    }
                    runInAction(() => {
                        targetRebate.payable = rebateAmount;
                    });
                }
            });
        });
    }

    getRebateSubTotal = (rangeCode) => {
        let subTotal = 0;
        const targetRange = this.rebateData.extendedRebateList.find((item) => item.rangeCode === rangeCode);
        targetRange.rebateList.forEach((item) => {
            if(item.hasOwnProperty('payable')){
                subTotal += item.payable;
            }
        });
        return subTotal;
    }

    getRebateTotal = () => {
        let totalAmount = 0;
        this.rebateData.extendedRebateList.forEach((rebate) => {
            rebate.rebateList.forEach((item) => {
                if(item.hasOwnProperty('payable')){
                    totalAmount += item.payable;
                }
            })
        });
        return totalAmount;
    }

    getFormData = () => {
        let editedFormData = [];
        this.rebateData.extendedRebateList.forEach((rebate) => {
            rebate.rebateList.forEach((item) => {
                if(item.hasOwnProperty('rebateId')){
                    if(this.getScrbMode() === 'state') {
                        editedFormData.push({
                            RebateId: item.rebateId,
                            StateQuantity: item.stateQuantity
                        })
                    } else {
                        editedFormData.push({
                            RebateId: item.rebateId,
                            VenueQuantity: item.venueQuantity
                        })
                    }
                }
            })
        });
        return editedFormData;
    }

    updateRebateData = (data) => {
        runInAction(() =>{
            this.rebateData = data
        })
    }

    completedBy = (event) => {
        runInAction(() => {
            this.rebateData.completedBy = event.target.value
        });
    }
    comment = (event) => {
        //console.log(event.target.value)
        runInAction(() => {
            this.rebateData.comment = event.target.value
        });
    }

    getScrbMode = () => {
        return this.scrb_mode;
    }

    logout = async () => {
        sessionStorage.clear();
        runInAction(()=>{
            this.scrb_mode = '';
        })
        runInAction(()=>{
            this.emailAddress = null;
        })
    }

    loginWithKey = async (emailAddress) => {
        const endpoint = `/Authentication/LoginWithKey`;
        const data = {
            "Email": emailAddress,
            "AccessKey": "34fee6f2-efbf-402d-9442-7f305168699c"
        };
        const result = await postToApi(JSON.stringify(data), endpoint);
        if(result.status === 204){
            let user = {
                emailAddress: emailAddress
            }
            runInAction(()=>{
                this.emailAddress = emailAddress;
            })
            if(emailAddress === 'mkong@lmg.com.au'){
                runInAction(()=>{
                    this.scrb_mode = 'state';
                })
                user.scrb_mode = 'store';
            } else {
                runInAction(()=>{
                    this.scrb_mode = 'store';
                })
                user.scrb_mode = 'store';
            }
            sessionStorage.setItem('@USER_DATA', JSON.stringify(user));
        }
        return result;
    }

    getPromotions = async () => {
        const endpoint = `/Legacy/GetPromotions?banner=${this.banner}`;
        const data = '';
        return await getFromApi(JSON.stringify(data), endpoint);
    }

    GetVenues = async (promoCode) => {
        const endpoint = `/Legacy/GetVenues?banner=${this.banner}&promoCode=${promoCode}`;
        const data = '';
        return await getFromApi(JSON.stringify(data), endpoint);
    }

    getRebates = async (promoCode,storeId) => {
        const endpoint = `/Legacy/GetRebates?banner=${this.banner}&promoCode=${promoCode}&storeCode=${storeId}`;
        const data = '';
        return await getFromApi(JSON.stringify(data), endpoint);
    }

    postRebates = async (data) => {
        const endpoint = `/Legacy/SaveRebates`;
        return await postToApi(JSON.stringify(data), endpoint);
    }

}
