import React, {useContext, useEffect} from 'react';
import {Container} from "@mui/material";
import {observer} from "mobx-react-lite";
import AppBar from "../shared/appBar";
import {Outlet, useParams} from "react-router-dom";
import Footer from "../shared/footer";
import {useMyTheme} from "../../App";
import {mainTheme} from "../shared/theme";
import {GlobalStore} from "../../stores/globalContext";

const MainScreen = observer((props) => {
    const { banner } = useParams();
    const {theme,setMyTheme} = useMyTheme();
    const stores = useContext(GlobalStore);
    useEffect(()=> {
        stores.member.setBanner(banner);
        setMyTheme(mainTheme(banner))
    },[banner])

  return (
      <>
          <AppBar/>
          <Container>
              <Outlet />
          </Container>
          <br />
          <Footer />
      </>
  )
})

export default MainScreen;
