import React, {useEffect, useContext} from 'react';
import {Typography} from "@mui/material";
import {GlobalStore} from "../../stores/globalContext";
import {observer} from "mobx-react-lite";
import ScreenTitle from "../shared/screenTitle";
import ToolBar from "./toolBar";
import VenueListTable from "./venueListTable";
import {useParams, useSearchParams} from "react-router-dom";

const VenueList = observer((props) => {
    const stores = useContext(GlobalStore);
    const [venueList, setVenueList] = React.useState([]);
    let { promoId } = useParams();
    let [searchParams, setSearchParams] = useSearchParams();

    useEffect(() => {
        stores.member.loginWithKey(stores.member.emailAddress).then(() => {
            stores.member.GetVenues(promoId).then((data) => {
                setVenueList(data);
            })
        })
    },[]);

    let venueListData = venueList;

// generate all the elements in the search area
    let venueTypes = [];
    let bannerTypes = [];
    let zoneNumbers = [];
    let venueStatusTypes = [];
    let stateStatusTypes = [];
    let stateList = [];

    venueListData.forEach(function(row) {
        for (let key in row) {
            let value = row[key];
            if (key === "memberType" && !venueTypes.includes(value)) {
                venueTypes.push(value);
            }
            if (key === "bannerName" && !bannerTypes.includes(value)) {
                bannerTypes.push(value);
            }
            if (key === "zoneNumber" && !zoneNumbers.includes(value)) {
                zoneNumbers.push(value);
            }
            if (key === "venueStatus" && !venueStatusTypes.includes(value)) {
                venueStatusTypes.push(value);
            }
            if (key === "stateStatus" && !stateStatusTypes.includes(value)) {
                stateStatusTypes.push(value);
            }
            if (key === "state" && !stateList.includes(value)) {
                stateList.push(value);
            }
        }
    });

    zoneNumbers.sort();

    // keep the selected status
    let selectedVenueTypes = (searchParams.get('type') || "").split(',');
    let search_venue_type = "";
    for (let i = 0; i < selectedVenueTypes.length; i++) {
        search_venue_type += venueTypes.indexOf(selectedVenueTypes[i]) + ",";
    }

    let selectedBanner = (searchParams.get('banner') || "").split(',');
    let search_venue_banner = "";
    for (let i = 0; i < selectedBanner.length; i++) {
        search_venue_banner += bannerTypes.indexOf(selectedBanner[i]) + ",";
    }

    let selectedZone = (searchParams.get('zone') || "").split(',');
    let search_venue_zone = "";
    for (let i = 0; i < selectedZone.length; i++) {
        search_venue_zone += zoneNumbers.indexOf(selectedZone[i]) + ",";
    }

    let selectedVenueStatus = (searchParams.get('vstatus') || "").split(',');
    let search_venue_store_status = "";
    for (let i = 0; i < selectedVenueStatus.length; i++) {
        search_venue_store_status += venueStatusTypes.indexOf(selectedVenueStatus[i]) + ",";
    }

    let selectedStateStatus = (searchParams.get('sstatus') || "").split(',');
    let search_venue_state_status = "";
    for (let i = 0; i < selectedStateStatus.length; i++) {
        search_venue_state_status += stateStatusTypes.indexOf(selectedStateStatus[i]) + ",";
    }

    let selectedState = (searchParams.get('state') || "").split(',');
    let search_venue_state = "";
    for (let i = 0; i < selectedState.length; i++) {
        search_venue_state += stateList.indexOf(selectedState[i]) + ",";
    }

// Filter the data in order
// filter by venue types
    let filteredByVenue;
    if (searchParams.get('type')) {
        filteredByVenue = [];
        for (let i = 0; i < venueTypes.length; i++) {
            if (selectedVenueTypes.includes(venueTypes[i])) {
                filteredByVenue = filteredByVenue.concat(venueListData.filter(function(props) {
                    return props.memberType === venueTypes[i];
                }));
            }
        }
    } else {
        filteredByVenue = venueListData;
    }

// filter by banner types
    let filteredByBanner;
    if (searchParams.get('banner')) {
        filteredByBanner = [];
        for (let i = 0; i < bannerTypes.length; i++) {
            if (selectedBanner.includes(bannerTypes[i])) {
                filteredByBanner = filteredByBanner.concat(filteredByVenue.filter(function(props) {
                    return props.bannerName === bannerTypes[i];
                }));
            }
        }
    } else {
        filteredByBanner = filteredByVenue;
    }

// filter by venue zone
    let filteredByZone;
    if (searchParams.get('zone')) {
        filteredByZone = [];
        for (let i = 0; i < zoneNumbers.length; i++) {
            if (selectedZone.includes(zoneNumbers[i])) {
                filteredByZone = filteredByZone.concat(filteredByBanner.filter(function(props) {
                    return props.zoneNumber === zoneNumbers[i];
                }));
            }
        }
    } else {
        filteredByZone = filteredByBanner;
    }

// filter by venue status
    let filteredByVenueStatus;
    if (searchParams.get('vstatus')) {
        filteredByVenueStatus = [];
        for (let i = 0; i < venueStatusTypes.length; i++) {
            if (selectedVenueStatus.includes(venueStatusTypes[i])) {
                filteredByVenueStatus = filteredByVenueStatus.concat(filteredByZone.filter(function(props) {
                    return props.venueStatus === venueStatusTypes[i];
                }));
            }
        }
    } else {
        filteredByVenueStatus = filteredByZone;
    }

    // filter by state status
    let filteredByStateStatus;
    if (searchParams.get('sstatus')) {
        filteredByStateStatus = [];
        for (let i = 0; i < stateStatusTypes.length; i++) {
            if (selectedStateStatus.includes(stateStatusTypes[i])) {
                filteredByStateStatus = filteredByStateStatus.concat(filteredByVenueStatus.filter(function(props) {
                    return props.stateStatus === stateStatusTypes[i];
                }));
            }
        }
    } else {
        filteredByStateStatus = filteredByVenueStatus;
    }

    // filter by state
    let filteredByState;
    if (searchParams.get('state')) {
        filteredByState = [];
        for (let i = 0; i < stateList.length; i++) {
            if (selectedState.includes(stateList[i])) {
                filteredByState = filteredByState.concat(filteredByStateStatus.filter(function(props) {
                    return props.state === stateList[i];
                }));
            }
        }
    } else {
        filteredByState = filteredByStateStatus;
    }

    // search by name
    if (searchParams.get('name')) {
        let search_text = searchParams.get('name');
        filteredByState = filteredByState.filter(function(item) {
            return item.venueName.toUpperCase().includes(search_text.toUpperCase());
        });
    }
    let finalShowingData = filteredByState;

    function reformatDate(curDate) {
        let dateArr = curDate.substr(0, 10).split('-');
        return dateArr[2] + '/' + dateArr[1] + '/' + dateArr[0];
    }

    return (
        <div>
            <ScreenTitle title={"Venue List"}/>
            <Typography variant={'h5'} fontFamily={'Rubik'} fontWeight={600} mb={3}>{promoId}</Typography>
            <ToolBar
                search_venue_type={search_venue_type}
                search_venue_banner={search_venue_banner}
                search_venue_zone={search_venue_zone}
                search_venue_store_status={search_venue_store_status}
                search_venue_state_status={search_venue_state_status}
                search_venue_state={search_venue_state}

                venueStatusTypes={venueStatusTypes}
                venueTypes={venueTypes}
                bannerTypes={bannerTypes}
                zoneNumbers={zoneNumbers}
                stateStatusTypes={stateStatusTypes}
                stateList={stateList}
            />
            <VenueListTable data={finalShowingData} />
        </div>
    )
})

export default VenueList;
