import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import {Container} from "@mui/material";
import {Person} from "@mui/icons-material";
import logo from '../../images/TC_WA_LOGO.png'
import {useContext} from "react";
import {GlobalStore} from "../../stores/globalContext";
import {useNavigate, useParams} from "react-router-dom";
import {Logo} from "./theme";


export default () => {
    const stores = useContext(GlobalStore);
    const navigate = useNavigate();
    const { banner } = useParams();
    const handleLogout = async (event) => {
        await stores.member.logout();
        navigate(`/${banner}/login`);
    }

    return (
    <Box sx={{flexGrow: 1}}>
        <AppBar position="static">
            <Container>
                <Toolbar>
                    <IconButton
                        size="large"
                        edge="start"
                        color="inherit"
                        aria-label="menu"
                        sx={{mr: 0}}
                    >
                        <Person/>
                        <Typography variant="subtitle1" component="div" sx={{flexGrow: 1}} mt={0.5}>
                            {stores.member.emailAddress}
                        </Typography>
                    </IconButton>
                    <div style={{
                        display: "flex",
                        flex: 1,
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '100%',
                        marginLeft: -100
                    }}>
                        <Logo banner={banner}/>
                    </div>
                    <Button color="inherit" onClick={handleLogout}>Logout</Button>
                </Toolbar>
            </Container>
        </AppBar>
    </Box>
    )
}
