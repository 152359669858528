import {observer} from "mobx-react-lite";
import React from "react";
import { TextField, Button, Typography, Stack, FormControl, Grid} from "@mui/material";

import Box from "@mui/material/Box";
import MultipleSelectCheckmarks from "./selectCheck";
import {useSearchParams} from "react-router-dom";

const ToolBar = observer((props) => {
    let [searchParams, setSearchParams] = useSearchParams();

    const handleNameSearch = (event) => {
        const {
            target: { value },
        } = event;

        setSearchParams(searchParams => {
            searchParams.set("name", value);
            return searchParams;
        });
    }

    return (
        <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>

                <Grid item xs={1.5}>
                    <Stack direction="row"
                           spacing={2}
                           mb={0}
                           justifyContent={"flex-end"}
                           alignItems="center">
                        <Typography>Venue Name:</Typography>
                    </Stack>
                </Grid>

                <Grid item xs={5.5}>
                    <FormControl fullWidth={true}>
                        <TextField id="outlined-basic"
                                   label="Venue Name"
                                   variant="outlined"
                                   size="small"
                                   value={searchParams.get('name') ? searchParams.get('name') : ""}
                                   onChange={handleNameSearch}
                        />
                    </FormControl>
                </Grid>

                <Grid item xs={5}>
                </Grid>

                <Grid item xs={1.5}>
                    <Stack direction="row"
                           spacing={2}
                           mb={0}
                           justifyContent={"flex-end"}
                           alignItems="center">
                    <Typography>Venue type:</Typography>
                    </Stack>
                </Grid>

                <Grid item xs={2}>
                    <MultipleSelectCheckmarks
                        label={"Venue type"}
                        searchParam={"type"}
                        optionList={props.venueTypes}
                        searchStatus={props.search_venue_type}/>
                </Grid>

                <Grid item xs={1.5}>
                    <Stack direction="row"
                           spacing={2}
                           mb={0}
                           justifyContent={"flex-end"}
                           alignItems="center">
                    <Typography>Banner:</Typography>
                    </Stack>
                </Grid>

                <Grid item xs={2}>
                    <MultipleSelectCheckmarks
                        label={"Banner"}
                        searchParam={"banner"}
                        optionList={props.bannerTypes}
                        searchStatus={props.search_venue_banner}/>
                </Grid>

                <Grid item xs={1.5}>
                    <Stack direction="row"
                           spacing={2}
                           mb={0}
                           justifyContent={"flex-end"}
                           alignItems="center">
                        <Typography>Zone:</Typography>
                    </Stack>
                </Grid>

                <Grid item xs={2}>
                    <MultipleSelectCheckmarks
                        label={"Zone"}
                        searchParam={"zone"}
                        optionList={props.zoneNumbers}
                        searchStatus={props.search_venue_zone}/>
                </Grid>

                <Grid item xs={1.5}>
                </Grid>

                <Grid item xs={1.5}>
                    <Stack direction="row"
                           spacing={2}
                           mb={0}
                           justifyContent={"flex-end"}
                           alignItems="center">
                        <Typography>Venue status:</Typography>
                    </Stack>
                </Grid>

                <Grid item xs={2}>
                    <MultipleSelectCheckmarks
                        label={"Venue status"}
                        searchParam={"vstatus"}
                        optionList={props.venueStatusTypes}
                        searchStatus={props.search_venue_store_status}/>
                </Grid>

                <Grid item xs={1.5}>
                    <Stack direction="row"
                           spacing={2}
                           mb={0}
                           justifyContent={"flex-end"}
                           alignItems="center">
                        <Typography>State status:</Typography>
                    </Stack>
                </Grid>

                <Grid item xs={2}>
                    <MultipleSelectCheckmarks
                        label={"State status"}
                        searchParam={"sstatus"}
                        optionList={props.stateStatusTypes}
                        searchStatus={props.search_venue_state_status}/>
                </Grid>

                <Grid item xs={1.5}>
                    <Stack direction="row"
                           spacing={2}
                           mb={0}
                           justifyContent={"flex-end"}
                           alignItems="center">
                        <Typography>State:</Typography>
                    </Stack>
                </Grid>

                <Grid item xs={2}>
                    <MultipleSelectCheckmarks
                        label={"State"}
                        searchParam={"state"}
                        optionList={props.stateList}
                        searchStatus={props.search_venue_state}/>
                </Grid>

                <Grid item xs={1.5}>
                </Grid>

                <Grid item xs={8}>

                </Grid>
                <Grid item xs={4}>
                    {
                        /*
                        <Stack direction="row"
                           spacing={2}
                           mb={3}
                           justifyContent="center"
                           alignItems="center" flex={1}>
                        <Button variant="outlined" size="medium" fullWidth={true} style={{padding: 7}}>Search</Button>
                        <Button variant="outlined" size="medium" fullWidth={true} style={{padding: 7}}>Reset</Button>
                    </Stack>
                         */
                    }

                </Grid>
            </Grid>
        </Box>
    )
})

export default ToolBar;
