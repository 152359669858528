import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import {CircularProgress} from "@mui/material";
import {StyledTableCell, StyledTableCellBody} from "../shared/tableStyle";
import {styled} from "@mui/material/styles";
import {useContext} from "react";
import {GlobalStore} from "../../stores/globalContext";
import MyTableBody from './myTableBody';
import {observer} from "mobx-react-lite";

export const DateFormat = "DD/MM/YYYY";

export const StyledTableRow = styled(TableRow)(({ theme, index }) => {
    if(index){
        return {
            backgroundColor: index % 2 === 0 ? '#FFFFFF' : '#E8E8E8' ,
            "&.MuiTableRow-hover:hover": {
                cursor: "pointer",
                backgroundColor: theme.palette.grey["300"],
            }
        }
    }
    return {
        '&:nth-of-type(odd)': {
            backgroundColor: '#FFFFFF',
        },
        '&:nth-of-type(even)': {
            backgroundColor: '#E8E8E8'
        },
        "&.MuiTableRow-hover:hover": {
            cursor: "pointer",
            backgroundColor: theme.palette.grey["300"],
        }
    }
});

const RebateListTable = observer((props) => {
    const stores = useContext(GlobalStore);
    const [totalAmount, setTotalAmount] = React.useState(0);

    const handleTotalAmount = () => {
        setTotalAmount(stores.member.getRebateTotal());
    }

    if(!props.data || props.data.length < 1) {
        return <CircularProgress />
    }

    return (
        <TableContainer >
            <Table sx={{ minWidth: 650 }} aria-label="Rebate Table">
                <TableHead>
                    <TableRow>
                        <StyledTableCell>Range Code</StyledTableCell>
                        <StyledTableCell align="left">Promoted Product</StyledTableCell>
                        <StyledTableCell align="left">Volume</StyledTableCell>
                        <StyledTableCell align="left">Multi-Buy QTY</StyledTableCell>
                        <StyledTableCell align="left">Product Promotion Period</StyledTableCell>
                        <StyledTableCell align="left">Rebate per Promoted Product</StyledTableCell>
                        {
                            props.data.memberType === 'NostraData' ?
                                <StyledTableCell align="left">Scan QTY</StyledTableCell>
                                : null
                        }
                        <StyledTableCell align="left">Member adjusted QTY</StyledTableCell>
                        {
                            stores.member.getScrbMode() === 'state' ?
                                <StyledTableCell align="left">Approved QTY</StyledTableCell>
                                : null
                        }
                        <StyledTableCell align="left">Total Payable</StyledTableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <MyTableBody {...props} handleTotalAmount={handleTotalAmount} />
                    <TableRow style={{border: 'none'}}>
                        <StyledTableCellBody style={{border: 'none'}}></StyledTableCellBody>
                        <StyledTableCellBody style={{border: 'none'}}></StyledTableCellBody>
                        <StyledTableCellBody style={{border: 'none'}}></StyledTableCellBody>
                        <StyledTableCellBody style={{border: 'none'}}></StyledTableCellBody>
                        <StyledTableCellBody style={{border: 'none'}}></StyledTableCellBody>
                        <StyledTableCellBody style={{border: 'none'}}></StyledTableCellBody>
                        {
                            props.data.memberType === 'NostraData' ?
                        <StyledTableCellBody style={{border: 'none'}}></StyledTableCellBody>
                                : null
                        }
                        {
                            stores.member.getScrbMode() === 'state' ?
                        <StyledTableCellBody style={{border: 'none'}}></StyledTableCellBody>
                                : null
                        }
                        <StyledTableCellBody align="left">
                            <span>Total <br/>Rebate</span>
                            <br />
                            <span>excl GST</span>
                        </StyledTableCellBody>
                        <StyledTableCellBody align="left">
                            <span style={{fontWeight: 600}}>${totalAmount.toFixed(2)}</span>
                        </StyledTableCellBody>
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
    );
})
export default RebateListTable;
