import {observer} from "mobx-react-lite";
import React from "react";
import {MenuItem, TextField, Button, Typography, Stack, FormControl} from "@mui/material";

const ToolBar = observer((props) => {
    return (
        <Stack direction="row"
               alignItems="center">
            <Stack direction="row"
                   spacing={2}
                   mb={3}
                   //justifyContent="center"
                flex={2}
                   alignItems="center">
                <Typography>Promotion No.:</Typography>
                <FormControl sx={{ width: '20ch' }}>
                    <TextField
                        id="outlined-basic"
                        label=""
                        variant="outlined"
                        size="small"
                        value={props.promoSearch !== 'all' ? props.promoSearch : ''}
                        onChange={props.handlePromoSearch}
                    />
                </FormControl>
                <Typography>Promotion Year:</Typography>
                <FormControl sx={{ width: '20ch' }}>
                    <TextField
                        id="outlined-select-Year"
                        select
                        label="Select"
                        value={props.selectedYear}
                        onChange={props.handleSelectedYear}
                        size="small"
                    >
                        {props.years.map((year) => (
                            <MenuItem key={year} value={year}>
                                {year}
                            </MenuItem>
                        ))}
                    </TextField>
                </FormControl>
            </Stack>
            {
                /*
                <Stack direction="row"
                   spacing={2}
                   mb={3}
                   justifyContent="center"
                   alignItems="center" flex={1}>
                <Button variant="outlined" size="medium" fullWidth={true} style={{padding: 7}}>Search</Button>
                <Button variant="outlined" size="medium" fullWidth={true} style={{padding: 7}}>Reset</Button>
            </Stack>
                 */
            }

        </Stack>
    )
})

export default ToolBar;
