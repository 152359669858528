import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import {Container} from "@mui/material";
import {Person} from "@mui/icons-material";
import {Logo} from "../shared/theme"
import {Copyright} from "../login/login";
import {useParams} from "react-router-dom";

export default () => {
    const { banner } = useParams();
    return (
    <Box sx={{
        flexGrow: 1,
        backgroundColor: 'primary.main',
        height: 200
    }}>
        <Container>
            <div style={{
                display: "flex",
                flex: 1,
                height: 150,
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%'
            }}>
                <Logo banner={banner}/>
            </div>
            <Copyright/>
        </Container>
    </Box>
    )
}
