import TClogo from "../../images/TC_WA_LOGO.png";
import BMlogo from "../../images/BottlemartLogo.png";
import HBlogo from "../../images/HarryBrown.png";
import SSlogo from "../../images/SipNSaveLogo.png";
import * as React from "react";
import {createTheme} from "@mui/material/styles";
import {orange} from "@mui/material/colors";

export const Logo = (props) => {
    switch(props.banner) {
        case 'thirstycamel':
            // code block
            return <img src={TClogo} alt="Logo" style={{height: 80}}/>
            //theme.palette.text.primary = "#000000"
            break;
        case 'bottlemart':
            // code block
            return <img src={BMlogo} alt="Logo" style={{height: 80}}/>
            //theme.palette.text.primary = "#FFFFFF"
            break;
        case 'sipnsave':
            // code block
            return <img src={SSlogo} alt="Logo" style={{height: 80}}/>
            //theme.palette.text.primary = "#000000"
            break;
        case 'harrybrown':
            // code block
            return <img src={HBlogo} alt="Logo" style={{height: 80}}/>
            break;
        default:
        // code block
    }

}

export const LoginImage = (banner) => {
    switch(banner) {
        case 'thirstycamel':
            // code block
            return 'https://pr0.nicelocal.com.au/98MAmf33Y-ghXUvnbCHslA/2252x1500,q75/4px-BW84_n0QJGVPszge3NRBsKw-2VcOifrJIjPYFYkOtaCZxxXQ2WeLfqP71lXbfzdHVqvqeyE2P90YFFPHnPRP34zLWr-QAJ_h7JZKORTgH0GUFZRL3Q'
            break;
        case 'bottlemart':
            // code block=
            return 'https://theshout.com.au/national-liquor-news/wp-content/uploads/sites/3/2018/07/Bottlemart.jpg'
            break;
        case 'sipnsave':
            // code block
            return 'https://img.agentaccount.com/bfdcbb50906f89b813f6f3b8599c1b24d02ebc83'
            break;
        case 'harrybrown':
            // code block
            return 'https://reefgatewayhotel.com.au/wp-content/uploads/2022/03/AVC_HarryBrownWSY_160321_016.jpg'
            break;
        default:
        // code block
    }
}

export const mainTheme = (banner) => {

    switch(banner) {
        case 'thirstycamel':
            // code block
            const tcTheme = createTheme({
                status: {
                    danger: orange[500],
                },
                palette: {
                    primary: {
                        //main: "#0054a6",
                        main: "rgb(160, 206, 102)",
                    },
                    secondary: {
                        main: "#000"
                    },
                    text: {
                        primary: "#232323",
                    }
                },
            });
            return(tcTheme)
            break;
        case 'bottlemart':
            // code block
            //mainTheme.palette.primary.main = "rgb(0, 84, 166)"
            const bottlemartTheme = createTheme({
                status: {
                    danger: orange[500],
                },
                palette: {
                    primary: {
                        //main: "#0054a6",
                        main: "rgb(0, 84, 166)",
                    },
                    secondary: {
                        main: "#FFF"
                    },
                    text: {
                        primary: "#232323",
                    }
                },
            });
            return(bottlemartTheme)
            //theme.palette.text.primary = "#FFFFFF"
            break;
        case 'sipnsave':
            // code block
            //mainTheme.palette.primary.main = "rgb(255, 221, 0)"
            const sipnsaveTheme = createTheme({
                status: {
                    danger: orange[500],
                },
                palette: {
                    primary: {
                        //main: "#0054a6",
                        main: "rgb(255, 221, 0)",
                    },
                    secondary: {
                        main: "#000"
                    },
                    text: {
                        primary: "#232323",
                    }
                },
            });
            return(sipnsaveTheme)
            //theme.palette.text.primary = "#000000"
            break;
        case 'harrybrown':
            // code block
            //mainTheme.palette.primary.main = "rgb(0, 0, 0)"
            const harryBrownTheme = createTheme({
                status: {
                    danger: orange[500],
                },
                palette: {
                    primary: {
                        //main: "#0054a6",
                        main: "rgb(0, 0, 0)",
                    },
                    secondary: {
                        main: "#FFF"
                    },
                    text: {
                        primary: "#232323",
                    }
                },
            });
            return(harryBrownTheme)
            break;
        default:
            // code block
            const mainTheme = createTheme({
                status: {
                    danger: orange[500],
                },
                palette: {
                    primary: {
                        //main: "#0054a6",
                        main: "rgb(160, 206, 102)",
                    },
                    text: {
                        //primary: "#FFFFFF"
                    }
                },
            });
            return mainTheme;
    }
}
