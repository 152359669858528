import React, {useEffect, useContext} from 'react';
import {Box, Button, CircularProgress, Stack, Typography} from "@mui/material";
import {GlobalStore} from "../../stores/globalContext";
import {observer} from "mobx-react-lite";
import ScreenTitle from "../shared/screenTitle";
import {useNavigate, useParams, useSearchParams} from "react-router-dom";
import Moment from "react-moment";
import {FormStatus} from "../../stores/functions";
import RebateListTable from "./rebateListTable";
import MyDocument from "./pdf";
import FormDialog from "./completedBy";
import CancelDialogSlide from "./cancelAndClose";
import MarkAsReviewedComment from "./markAsReviewed";

const CloseButton = (props) => {
    const stores = useContext(GlobalStore);
    let [searchParams,setSearchParams] = useSearchParams();
    const edit = searchParams.get('edit') === 'true';
    const navigate = useNavigate();
    const { banner } = useParams();

    const handleEditClick = () => {
        setSearchParams(searchParams => {
            searchParams.set("edit","true");
            return searchParams;
        });
    }

    const handleClose = () => {
        navigate(`/${banner}/venue-list/${stores.member.scanRebateData.promoCode}`);
    }

    if(stores.member.getScrbMode() === 'state'){
        if(props.rawGetRebateData['stateFormStatus'] === 'Available' ||
            props.rawGetRebateData['stateFormStatus'] === 'Reviewed'){
            if(edit) {
                return <Stack direction="row"
                              justifyContent={'space-between'}
                              spacing={2}
                              mb={3}>
                    <CancelDialogSlide />
                    <MarkAsReviewedComment />
                </Stack>
            } else {
                return <Stack direction="row"
                             justifyContent={'space-between'}
                             spacing={2}
                             mb={3}>
                    <Button variant="outlined"
                            size="large"
                            onClick={handleClose}
                            style={{paddingLeft: 50, paddingRight: 50}}>
                        Close
                    </Button>
                    <Button variant="outlined"
                            size="large"
                            onClick={handleEditClick}
                            style={{paddingLeft: 50, paddingRight: 50}}>
                        Edit
                    </Button>
                </Stack>
            }
        } else {
            return <Stack direction="row"
                          justifyContent={'space-between'}
                          spacing={2}
                          mb={3}>
                <Button variant="outlined"
                        size="large"
                        onClick={handleClose}
                        style={{paddingLeft: 50, paddingRight: 50}}>
                    Close
                </Button>
            </Stack>
        }
    } else {
        if(props.rawGetRebateData['venueFormStatus'] === 'Available' ||
            props.rawGetRebateData['venueFormStatus'] === 'Submitted') {
            if(edit) {
                return <Stack direction="row"
                              justifyContent={'space-between'}
                              spacing={2}
                              mb={3}>
                    <CancelDialogSlide />
                    <FormDialog />
                </Stack>
            } else {
                return <Stack direction="row"
                              justifyContent={'space-between'}
                              spacing={2}
                              mb={3}>
                    <Button variant="outlined"
                            size="large"
                            onClick={handleClose}
                            style={{paddingLeft: 50, paddingRight: 50}}>
                        Close
                    </Button>
                    <Button variant="outlined"
                            size="large"
                            onClick={handleEditClick}
                            style={{paddingLeft: 50, paddingRight: 50}}>
                        Edit
                    </Button>
                </Stack>
            }
        } else {
            return <Stack direction="row"
                   justifyContent={'space-between'}
                   spacing={2}
                   mb={3}>
                <Button variant="outlined"
                        size="large"
                        onClick={handleClose}
                        style={{paddingLeft: 50, paddingRight: 50}}>
                    Close
                </Button>
            </Stack>
        }
    }
}

const OnlineRebateForm = observer((props) => {
    const stores = useContext(GlobalStore);
    let { promoId,storeId } = useParams();
    let [searchParams, setSearchParams] = useSearchParams();
    const dateFormat = "DD/MM/YYYY";
    const edit = searchParams.get('edit') === 'true';
    const rebateData = stores.member.rebateData;

    useEffect(() => {
        stores.member.loginWithKey(stores.member.emailAddress).then(() => {
            stores.member.getRebates(promoId,storeId).then((data) => {
                stores.member.updateRebateData(data);
            })
        })
    },[]);

    const stateFormStatus = stores.member.getScrbMode() === 'state'
        ? FormStatus(stores.member.rebateData.stateFormStatus)
        : FormStatus(stores.member.rebateData.venueFormStatus)
    if(!stores.member.scanRebateData || stores.member.scanRebateData.length < 1) {
        return <CircularProgress />
    }

    return (
        <div>
            <ScreenTitle title={`Online Rebate Form`}/>
            <CloseButton rawGetRebateData={rebateData}/>

            <Stack direction="row" spacing={2} alignItems={'flex-end'}>
                <Typography variant={'h4'} fontFamily={'Rubik'} fontWeight={800} color={'primary'}>
                    {`${promoId} Scan rebate form`}
                </Typography>
                <Typography variant={'subtitle2'} fontWeight={400} mt={'10px'}>Form Status:</Typography>
                <Box p={'6px 18px'} alignItems={'center'} justifyContent={'center'} bgcolor={stateFormStatus.bgColor}>
                    <Typography fontWeight={800} color={stateFormStatus.fontColor}>{stores.member.getScrbMode() === 'state'
                        ? rebateData.stateFormStatus
                        : rebateData.venueFormStatus
                    }
                    </Typography>
                </Box>
            </Stack>
            <Stack direction="row" spacing={2} justifyContent={'space-between'} mt={3}>
                <Typography variant={'h6'} fontFamily={'Rubik'} fontWeight={600}>
                    {rebateData.stateName} - ZONE{rebateData.zoneNumber} - {rebateData.venueName}
                </Typography>
                <Button onClick={()=> MyDocument({data:stores.member.rebateData,storeId:storeId})} variant="outlined" style={{paddingLeft: 50, paddingRight: 50}}>Download</Button>
            </Stack>

            <Stack direction="row" spacing={2} mb={3}>
                <Box flex={2}>
                    <Typography variant={'subtitle2'} fontWeight={400} mt={'10px'}>Promotion Period:
                        <Moment date={rebateData.promotionStartDate} format={dateFormat} /> - <Moment date={rebateData.promotionEndDate} format={dateFormat} /></Typography>
                    <Typography variant={'subtitle2'} fontWeight={400} mt={'10px'}>Venue Lock Date: <Moment date={rebateData.venueLockDate} format={dateFormat} /></Typography>
                    <Typography variant={'subtitle2'} fontWeight={400} mt={'10px'}>State Lock Date: <Moment date={rebateData.stateLockDate} format={dateFormat} /></Typography>
                </Box>
                <Box flex={1}>
                    <Typography variant={'subtitle2'} fontWeight={400} mt={'10px'}>Date Submitted: <Moment date={rebateData.dateSubmitted} format={dateFormat} /></Typography>
                    <Typography variant={'subtitle2'} fontWeight={400} mt={'10px'}>Completed By: {rebateData.completedBy}</Typography>
                </Box>
            </Stack>

            <RebateListTable data={rebateData} edit={edit}/>

            <div className="scrb-attachment">
                <div>
                    <h3>Attachments - Proof of Sales</h3>
                </div>
                <div>

                    <a href="#" className="scrb-button scrb-action-attachment icons-attachment">
                        <span>Attach</span>
                    </a>
                    <span>Formats supported: pdf, jpg, png, ppt, doc. Max file size 32mb.</span>
                    <span>You must be in "EDIT" mode to upload any files.</span>
                </div>
            </div>

            <CloseButton rawGetRebateData={rebateData}/>

            <div className="scrb-disclaimer scrb-disclaimer-active">
                <h3>Disclaimer</h3>
                <div>
                    <span>Use of the portal is subject to the terms and conditions detailed below and the terms of the Membership Agreement between Liquor Marketing Group Limited A.C.N. 001 440 320 (“LMG”) and the Member;</span>
                    <span>Rebates payable by LMG on product sales (“Scan Rebates”) are a deferred discount on the products as advised within the LMG Promotional Advice and other LMG communications from time to time;</span>
                    <span>Scan Rebates entitlement is subject to compliance with the LMG Promotional Advice for the sale of the Product;</span>
                    <span>LMG reserves the right to vary LMG Promotional Advice and Scan Rebates at any time;</span>
                    <span>Scan Rebates are only payable on applicable products which are purchased through ALM, or another wholesaler nominated by LMG, or direct through the Supplier;</span>
                    <span>Scan Rebates are only payable on retail transactions to the end consumer. LMG reserves the right to reject Scan Rebate claims on sales of a wholesale or third party supply nature;</span>
                    <span>LMG reserves the right to request supporting information for any claim, including but not limited to, point of sale system reports, purchase orders or invoices and other supporting material;</span>
                    <span className="scrb-disclaimer-latin">The Member warrants:
			<span className="scrb-disclaimer-roman">that all data provided to LMG relating to the Scan Rebate claim is:
				<span>is accurate, complete and current;</span>
				<span>is provided within the timeframes advised and prior to the Scan Rebate lock date;</span>
				<span>is not misleading, deceptive or misstated;</span>
			</span>
			<span>that the person lodging the form on behalf of the Member has authority and capacity to do so and LMG may rely on the information provided;</span>
			<span>that the person entering into this Agreement on behalf of the Member has authority and capacity to do so;</span>
		</span>
                    <span>The Member acknowledges that the information provided may be used by LMG to make a claim against the respective Supplier for the Scan Rebate. The Member therefore grants LMG a non-exclusive, worldwide, royalty free licence to use, copy, transmit, display and store the Member data for the purpose of making this claim and maintaining LMG records;</span>
                    <span className="scrb-disclaimer-roman">The Member acknowledges:
			<span>Scan Rebate forms are required to be completed within the timeframe provided to facilitate LMG claim and payment;</span>
			<span>Scan Rebate forms will not be accepted by LMG  after the Venue Lock Date;</span>
			<span>LMG is not liable if information provided is inaccurate or incomplete;</span>
			<span>Scan Rebate forms with the status of “Submitted” are valid submissions;</span>
		</span>
                    <span>LMG reserves the right to reject or modify Scan Rebate submissions if there are reasonable grounds to believe that the submission is not in accordance with terms and conditions of this Agreement and the Membership agreement;</span>
                    <span>The Member must notify LMG if they suspect or become aware of unauthorised use of their LoginID and password or other security breach relating to your account;</span>
                    <span>LMG is not liable for indirect or consequential loss arising from or connected to this online platform, Scan Rebates and Scan Rebate Online Forms;</span>
                    <h3>Definitions</h3>
                    <span><strong>LMG:</strong> Liquor Marketing Group Limited ACN 001 440 320 and all its related bodies corporate;</span>
                    <span><strong>LMG Promotional Advice:</strong> refers to advice from LMG with pricing and promotional deals;</span>
                    <span><strong>Member:</strong> refers to the legal entity which entered Membership Agreement with LMG;</span>
                    <span><strong>Membership Agreement:</strong> refers to the Agreement entered into between the Member and LMG to become and LMG Member;</span>
                    <span><strong>Supplier:</strong> The producer, seller or other party who promotes the product for distribution and sale;</span>
                    <span><strong>You, Your:</strong> refers to the Member;</span>
                </div>
            </div>
        </div>
    )
})

export default OnlineRebateForm;

