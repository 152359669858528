import React, {useEffect, useContext} from 'react';
import {GlobalStore} from "../../stores/globalContext";
import {observer} from "mobx-react-lite";
import ScreenTitle from "../shared/screenTitle";
import ToolBar from "./toolBar";
import MainTable from "./mainTable";
import {useNavigate, useParams} from "react-router-dom";
import {CircularProgress} from "@mui/material";

const AllForms =
    observer((props) => {
    const stores = useContext(GlobalStore);
    const [promotions, setPromotions] = React.useState([]);
    const { promo,year,banner } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        stores.member.loginWithKey(stores.member.emailAddress).then(() => {
            stores.member.getPromotions().then((data) => {
                setPromotions(data);
            })
        })
    },[]);

    const handleSelectedYear = (event) => {
        //setAge(event.target.value);
        if(promo){
            navigate(`/${banner}/all-forms/${promo}/${event.target.value}`);
        } else {
            navigate(`/${banner}/all-forms/all/${event.target.value}`);
        }
    };

    const handlePromoSearch = (event) => {
        if(year && event.target.value) {
            navigate(`/${banner}/all-forms/${event.target.value}/${year}`);
        }else if(year && !event.target.value){
            navigate(`/${banner}/all-forms/all/${year}`);
        } else if (event.target.value) {
            navigate(`/${banner}/all-forms/${event.target.value}`);
        } else {
            navigate(`/${banner}/all-forms`);
        }
    };

    let promotionData = promotions
    // search by name
    if (promo && promo !== 'all') {
        let search_text = promo;
        promotionData = promotionData.filter(function(item) {
            return item['promotionCode'].toUpperCase().includes(search_text.toUpperCase());
        });
    }

    let yearsSet = new Set();
    let yearsArray = [];
    promotionData.forEach(function(item) {
        let promotionCode = item['promotionCode'];
        let year = promotionCode.split('-').pop();
        if (/^\d{4}$/.test(year)) {
            yearsSet.add(year);
        }
    });
    yearsArray.push(2023)
    yearsArray = Array.from(yearsSet);

    // search by year
    let selectedYear;
    if (year) {
        selectedYear = year;
    } else {
        selectedYear = new Date().getFullYear().toString();
    }

    let filteredByYear = selectedYear.split(',');
    promotionData = promotionData.filter(function(item) {
        return filteredByYear.includes(item['promotionCode'].split('-').pop());
    });

    if(!yearsArray || yearsArray.length < 1){
        return <div>
            <ScreenTitle title={"Scan Rebate Form"}/>
            <CircularProgress />
        </div>
    }

    return (
        <div>
            <ScreenTitle title={"Scan Rebate Form"}/>
            <ToolBar years={yearsArray}
                     selectedYear={selectedYear}
                     handleSelectedYear={handleSelectedYear}
                     handlePromoSearch={handlePromoSearch}
                     promoSearch={promo}
            />
            <MainTable data={promotionData} />
        </div>
    )
})

export default AllForms;




