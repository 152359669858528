import * as React from "react";
import {StyledTableCellBody} from "../shared/tableStyle";
import {IconButton, Stack} from "@mui/material";
import {StyledTableRow} from "./rebateListTable";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";
import CollapsedRow from "./collapsedRow";
import {useContext} from "react";
import {GlobalStore} from "../../stores/globalContext";
import {observer} from "mobx-react-lite";

const RowNostra = observer((props) => {
    const [openModule, setOpenModule] = React.useState(false);
    const [rebateAmount, setRebateAmount] = React.useState(0);
    const currentProduct = props.row.rebateList[0];
    const stores = useContext(GlobalStore);

    const handleRebateAmount = (rangeCode) => {
        setRebateAmount(stores.member.getRebateSubTotal(rangeCode));
    }

    return(
        <React.Fragment key={props.row['rangeCode']}>
            <StyledTableRow hover={true} onClick={() => setOpenModule(!openModule)} index={props.index+1}>
                <StyledTableCellBody component="th" scope="row">
                    <Stack direction="row"
                           spacing={0}
                           mb={0}
                           justifyContent={"flex-start"}
                           alignItems={"center"}>
                        <IconButton
                            disableFocusRipple
                            disableRipple
                            aria-label="expand row"
                            size="small"
                            onClick={() => setOpenModule(!openModule)}
                        >
                            {openModule ? <RemoveIcon /> : <AddIcon />}
                        </IconButton>
                        {props.row['rangeCode']}
                    </Stack>
                </StyledTableCellBody>
                <StyledTableCellBody align="left" style={{minWidth: 225}}>{currentProduct['rangeName']}</StyledTableCellBody>
                <StyledTableCellBody align="center">{props.row.volume}</StyledTableCellBody>
                <StyledTableCellBody align="center">{currentProduct['multibuyQuantity']}</StyledTableCellBody>
                <StyledTableCellBody align="center"><span></span></StyledTableCellBody>
                <StyledTableCellBody align="center"><span></span></StyledTableCellBody>
                <StyledTableCellBody align="center">{props.row.rebateList.map((item)=> amount(item,'scanQuantity')).reduce(sum)}</StyledTableCellBody>
                <StyledTableCellBody align="center">{props.row.rebateList.map((item) => amount(item,'venueQuantity')).reduce(sum)}</StyledTableCellBody>
                {stores.member.getScrbMode() === 'state'
                    ? <StyledTableCellBody align="center">{props.row.rebateList.map((item) => amount(item,'stateQuantity')).reduce(sum)}</StyledTableCellBody>
                    : null
                }
                <StyledTableCellBody align="center">${Number(rebateAmount).toFixed(2)}</StyledTableCellBody>
            </StyledTableRow>
            {props.row.rebateList.map((r, i) => (
                <CollapsedRow key={i} row={r} open={openModule}
                              handleRebateAmount={handleRebateAmount}
                              handleTotalAmount={props.handleTotalAmount}
                              edit={props.edit} data={props.data} />
            ))}
        </React.Fragment>
    )
});

export default RowNostra;

function amount(item,field){
    return Number(item[field]);
}

function sum(prev, next){
    return prev + next;
}
